<template>
  <v-card color="" elevation="0" outlined class="pa-0">
    <div class="card-header">
      <v-card-title class="d-flex justify-space-between pa-5">
        <div class="white--text headline">Ajouter Driver</div>
        <v-icon color="white" class="d-none d-sm-flex" @click="toggle()"
          >mdi-close-thick</v-icon
        >
      </v-card-title>
    </div>
    <v-divider />
    <v-card-text style="height: auto">
      <v-card elevation="0">
        <v-card-text class="pt-5 pb-0">
          <v-row align="center">
            <v-col cols="12" md="6">
              <v-row no-gutters v-for="item in formText" :key="item.id">
                <v-col cols="12" sm="5">
                  <v-subheader class="pa-0 subtitle-1 font-weight-bold">{{
                    item.title
                  }}</v-subheader>
                </v-col>
                <v-col cols="12" sm="7">
                  <v-text-field
                    outlined
                    dense
                    v-model="item.value"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters class="mb-7">
                <v-col cols="12" sm="5">
                  <v-subheader class="pa-0 subtitle-1 font-weight-bold">{{
                    "Phone Number"
                  }}</v-subheader>
                </v-col>
                <v-col cols="12" sm="7" class="d-flex flex-row align-center justify-center">
                  <vue-country-code
                    class="mr-2"
                    @onSelect="onSelect"
                    :enabledCountryCode="true"
                    :defaultCountry="'sn'"
                    :preferredCountries="['sn', 'fr', 'ma']"
                  >
                  </vue-country-code>
                  <v-text-field
                    outlined
                    dense
                    hide-details
                    v-mask="['#########']"
                    v-model="phone"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" sm="5">
                  <v-subheader class="pa-0 subtitle-1 font-weight-bold">
                    License Expiry Date</v-subheader
                  >
                </v-col>
                <v-col cols="12" sm="7">
                  <v-menu
                    v-model="dateMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="licenseExpiryDate"
                        dense
                        append-icon="mdi-calendar"
                        outlined
                        readonly
                        v-bind="attrs"
                        @blur="licenseDate = parseDate(licenseExpiryDate)"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      color="primary"
                      v-model="licenseDate"
                      @input="dateMenu = false"
                      locale="fr"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="5">
                  <v-subheader class="pa-0 subtitle-1 font-weight-bold">
                    Date of birth</v-subheader
                  >
                </v-col>
                <v-col cols="12" sm="7">
                  <v-menu
                    v-model="dateMenu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dob"
                        dense
                        append-icon="mdi-calendar"
                        outlined
                        readonly
                        v-bind="attrs"
                        @blur="dobDate = parseDate(dob)"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      color="primary"
                      v-model="dobDate"
                      @input="dateMenu2 = false"
                      locale="fr"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="6">
              <address-picker :getAddress="getAddress" />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-card-text>
    <v-divider />
    <v-card-actions>
      <v-row no-gutters justify-sm="space-between">
        <v-col cols="12" sm="auto" order="2" order-sm="1">
          <v-btn
            :block="$vuetify.breakpoint.xs ? true : false"
            color="grey"
            class="mt-5 mt-sm-0"
            elevation="0"
            dark
            @click="toggle()"
            >Cancel
          </v-btn>
        </v-col>
        <v-col cols="12" sm="auto" order="1" order-sm="1">
          <v-btn
            v-if="addressConfirmed"
            :block="$vuetify.breakpoint.xs ? true : false"
            color="primary"
            elevation="0"
            dark
            @click.once="addNewDriver"
          >
            Confirm
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import AddressPicker from "./AddressPicker.vue";
import * as fb from "../../firebase";
import { mask } from "vue-the-mask";

export default {

  directives: { mask },

  components: { AddressPicker },

  props: {
    toggle: {
      type: Function,
      required: false,
    },

    getDrivers: {
      type: Function,
      required: false,
    },
  },

  data: () => ({
    addressConfirmed: false,
    dateMenu: false,
    dateMenu2: false,
    licenseDate: "",
    licenseExpiryDate: "",
    dobDate: "",
    dob: "",
    address: null,
    countryCode: "",
    phone: "",
    phoneNumber: "",
    formText: [
      {
        id: "firstName",
        title: "First Name",
        value: "",
      },
      {
        id: "lastName",
        title: "Last Name",
        value: "",
      },
      {
        id: "email",
        title: "Email",
        value: "",
      },
      {
        id: "password",
        title: "Password",
        value: "",
      },
      {
        id: "licenseNumber",
        title: "License Number",
        value: "",
      },
    ],
  }),

  watch: {
    licenseDate(val) {
      this.licenseExpiryDate = this.formatDate(this.licenseDate);
    },

    dobDate(val) {
      this.dob = this.formatDate(this.dobDate);
    },
  },

  methods: {
    onSelect({ name, iso2, dialCode }) {
      this.countryCode = `+${dialCode}`;
      console.log(name, iso2, dialCode);
    },

    getAddress(payload) {
      this.address = payload;
      this.addressConfirmed = !this.addressConfirmed;
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("-");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },

    async addNewDriver() {
      let driverObject = {
        customId: `SN-CH-${Math.floor(Math.random() * 1000000)}`,
        uid: "",
        firstName: "",
        lastName: "",
        dob: "",
        email: "",
        countryCode: this.countryCode,
        phone: this.phone,
        phoneNumber: `${this.countryCode} ${this.phone}`,
        address: "",
        imageURL: "",
        licenseNumber: "",
        licenseExpiryDate: "",
        status: "Active",
        upcomingTrip: {},
        createdAt: new Date().getTime(),
        assignedVans: [],
      };

      this.formText.forEach((data) => {
        if (data.id == "firstName") {
          driverObject.firstName = data.value;
        } else if (data.id == "lastName") {
          driverObject.lastName = data.value;
        } else if (data.id == "email") {
          driverObject.email = data.value;
        } else if (data.id == "phoneNumber") {
          driverObject.phoneNumber = data.value;
        } else if (data.id == "licenseNumber") {
          driverObject.licenseNumber = data.value;
        }
      });

      driverObject.firstName = this.formText[0].value;
      driverObject.lastName = this.formText[1].value;
      driverObject.email = this.formText[2].value;
      driverObject.licenseNumber = this.formText[4].value;

      driverObject.licenseExpiryDate = this.licenseExpiryDate;
      driverObject.dob = this.dob;
      driverObject.address = this.address;

      // console.log(driverObject);

      const newUser = await fb.auth.createUserWithEmailAndPassword(
        this.formText[2].value,
        this.formText[3].value
      );

      driverObject.uid = newUser.user.uid;

      let status = await this.$store.dispatch(
        "driver/createDriver",
        driverObject
      );

      if (status.code === 1) {
        this.toggle();
        this.getDrivers();
      } else {
        this.toggle();
      }
    },
  },
};
</script>

<style></style>
